import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { domElementGetter } from '@toasttab/ec-layout'
import TeamApp from './app/TeamApp'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'data-pui-2',
  rootComponent: TeamApp,
  domElementGetter: domElementGetter.application,
  portalContainers: ['banquetPortalsContainer'],

  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-team-spa'

sessionStorage.setItem(
  `sentry:${name}`,
  `https://2ac21c87753443fe96a1ef139b48d1a3@o37442.ingest.sentry.io/1866872`
)
